import BaseLayout from "@components/BaseLayout"
import { MENU_HEIGHT } from "@components/BaseLayout/Header/Header.styles"
import SEO from "@components/Seo"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { Box } from "rebass"

function JobsPage() {
  const { metatag } = useStaticQuery(graphql`
    query Jobs {
      metatag: markdownRemark(
        fileAbsolutePath: { glob: "**/content/metatags/trabalhe-conosco.md" }
      ) {
        frontmatter {
          languages {
            language
            keywords
            title
            description
          }
        }
      }
    }
  `)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  return (
    <BaseLayout hideHeaderButtons hideMenu hideFooter>
      <SEO {...metatags} />
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          height: MENU_HEIGHT.map(
            height => `calc(var(--app-height) - ${height}px)`
          ),
          top: MENU_HEIGHT,
        }}
      >
        <ReactTypeformEmbed
          style={{
            position: "relative",
          }}
          url="https://xapps.typeform.com/to/k1I9D9"
        />
      </Box>
    </BaseLayout>
  )
}

export default JobsPage
